/* eslint-disable no-console */
import axios from 'axios'
import { cancel, delay, fork, put, take } from 'redux-saga/effects'

import { update, UPDATES_START, UPDATES_STOP } from '.'

function* runUpdates({ payload }) {
  try {
    while (true) {
      const { data } = yield axios.get(
        `https://discordapp.com/api/guilds/${payload.serverId}/widget.json`,
        {
          params: { t: Math.round(Date.now() / 1000) },
        },
      )
      yield put(update(data))
      yield delay(payload.updateInterval)
    }
  } catch (e) {
    console.error(e)
  }
}

export default function* () {
  const action = yield take(UPDATES_START)
  while (action) {
    const updatesTask = yield fork(runUpdates, action)

    yield take(UPDATES_STOP)
    yield cancel(updatesTask)
  }
}
