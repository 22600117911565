import { createAction, handleActions } from 'redux-actions'

// State
const initialState = {
  isLoggedIn: false,
  userData: {},
  currentBranch: '',
  currentSHA: '',
  currentRepoOwner: '',
  snackbar: {
    open: false,
    message: '',
    severity: '',
  },
  pullRequests: [],
  hasWritePermissions: false,
}

// ActionTypes
export const LOGIN = 'EDITOR/LOGIN'
export const LOGOUT = 'EDITOR/LOGOUT'

export const SET_CURRENT_BRANCH = 'EDITOR/SET_CURRENT_BRANCH'
export const SET_CURRENT_SHA = 'EDITOR/SET_CURRENT_SHA'
export const SET_CURRENT_REPO_OWNER = 'EDITOR/SET_CURRENT_REPO_OWNER'

export const SET_PULL_REQUESTS = 'EDITOR/SET_PULL_REQUESTS'
export const SET_WRITE_PERMISSIONS = 'EDITOR/SET_WRITE_PERMISSIONS'

// Actions
export const login = createAction(LOGIN)
export const logout = createAction(LOGOUT)

export const setCurrentBranch = createAction(SET_CURRENT_BRANCH)
export const setCurrentSHA = createAction(SET_CURRENT_SHA)
export const setCurrentRepoOwner = createAction(SET_CURRENT_REPO_OWNER)
export const setPullRequests = createAction(SET_PULL_REQUESTS)
export const setWritePermissions = createAction(SET_WRITE_PERMISSIONS)

// Reducers
export default handleActions(
  {
    [LOGIN]: (state, { payload }) => ({
      ...state,
      isLoggedIn: true,
      userData: payload.userData,
    }),
    [LOGOUT]: () => initialState,
    [SET_CURRENT_BRANCH]: (state, { payload }) => ({
      ...state,
      currentBranch: payload,
    }),
    [SET_CURRENT_SHA]: (state, { payload }) => ({
      ...state,
      currentSHA: payload,
    }),
    [SET_CURRENT_REPO_OWNER]: (state, { payload }) => ({
      ...state,
      currentRepoOwner: payload,
    }),
    [SET_PULL_REQUESTS]: (state, { payload }) => ({
      ...state,
      pullRequests: payload,
    }),
    [SET_WRITE_PERMISSIONS]: (state, { payload }) => ({
      ...state,
      hasWritePermissions: payload,
    }),
  },
  initialState,
)

// Selectors
export const isLoggedIn = (state) => state.editor.isLoggedIn
export const getUserData = (state) => state.editor.userData
export const getCurrentBranch = (state) => state.editor.currentBranch
export const getCurrentSHA = (state) => state.editor.currentSHA
export const getCurrentRepoOwner = (state) => state.editor.currentRepoOwner
export const getPullRequests = (state) => state.editor.pullRequests
export const getWritePermissions = (state) => state.editor.hasWritePermissions
