import {
  applyMiddleware,
  compose,
  createStore as reduxCreateStore,
} from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '.'
import discordSagas from './discord/discord.sagas'
import fractalDailiesSagas from './fractals/dailies/dailies.sagas'
import fractalGodSagas from './fractals/god/god.sagas'
import storage from './storage'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['editor', 'discord', 'fractals', 'general'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const saga = createSagaMiddleware()

const enhancer = applyMiddleware(saga)

const composeEnhancers =
  (typeof window !== 'undefined' &&
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

export default () => {
  const store = reduxCreateStore(persistedReducer, composeEnhancers(enhancer))
  const persistor = persistStore(store)

  saga.run(discordSagas)
  saga.run(fractalDailiesSagas)
  saga.run(fractalGodSagas)

  if (module.hot) {
    /* eslint global-require: off */
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./index', () => {
      const nextRootReducer = require('.')
      store.replaceReducer(nextRootReducer)
    })
  }

  return { store, persistor }
}
