import { muiTheme } from '@discretize/globals';
import { createTheme } from '@mui/material/styles';

const theme = createTheme(muiTheme, {
  typography: {
    body1: {
      fontFamily: 'Raleway',
    },
    body2: {
      fontFamily: 'Raleway',
    },
  },
});
export default theme;
