import { createAction, handleActions } from 'redux-actions'

// State
const initialState = {
  result: {},
  error: '',
}

// ActionTypes
export const FRACTALS_GOD_CALCULATE_REQUESTED =
  'FRACTALS/GOD/CALCULATE_REQUESTED'
export const FRACTALS_GOD_CALCULATE_SUCCEEDED =
  'FRACTALS/GOD/CALCULATE_SUCCEEDED'
export const FRACTALS_GOD_CALCULATE_FAILED = 'RACTALS/GOD/CALCULATE_FAILED'

// Actions
export const calculateRequest = createAction(FRACTALS_GOD_CALCULATE_REQUESTED)
export const calculateSucceed = createAction(FRACTALS_GOD_CALCULATE_SUCCEEDED)
export const calculateFail = createAction(FRACTALS_GOD_CALCULATE_FAILED)

// Reducers
export default handleActions(
  {
    [FRACTALS_GOD_CALCULATE_REQUESTED]: (state) => ({
      ...state,
      result: {},
      error: '',
    }),
    [FRACTALS_GOD_CALCULATE_SUCCEEDED]: (state, { payload: result }) => ({
      ...state,
      result,
      error: '',
    }),
    [FRACTALS_GOD_CALCULATE_FAILED]: (state, { payload: error }) => ({
      ...state,
      result: {},
      error,
    }),
  },
  initialState,
)

// Selectors
export const getResult = (state) => state.fractals.god.result
export const getError = (state) => state.fractals.god.error
