import { createAction, handleActions } from 'redux-actions'

// State
const initialState = {
  onlineMembers: 0,
  inviteLink: '',
}

// ActionTypes
export const UPDATE = 'DISCORD/UPDATE'
export const UPDATES_START = 'DISCORD/UPDATES/START'
export const UPDATES_STOP = 'DISCORD/UPDATES/STOP'

// Actions
export const update = createAction(UPDATE)
export const startUpdates = createAction(UPDATES_START)
export const stopUpdates = createAction(UPDATES_STOP)

// Reducers
export default handleActions(
  {
    [UPDATE]: (state, { payload }) => ({
      ...state,
      inviteLink: payload.instant_invite,
      onlineMembers: payload.presence_count,
    }),
  },
  initialState,
)

// Selectors
export const getInviteLink = (state) => state.discord.inviteLink
export const getOnlineMembers = (state) => state.discord.onlineMembers
