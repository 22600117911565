import { createAction, handleActions } from 'redux-actions'

// State
const initialState = []

// ActionTypes
export const UPDATE = 'FRACTALS/DAILIES/UPDATE'
export const UPDATES_START = 'FRACTALS/DAILIES/UPDATES/START'
export const UPDATES_STOP = 'FRACTALS/DAILIES/UPDATES/STOP'

// Actions
export const update = createAction(UPDATE)
export const startUpdates = createAction(UPDATES_START)
export const stopUpdates = createAction(UPDATES_STOP)

// Reducers
export default handleActions(
  {
    [UPDATE]: (state, { payload }) => payload,
  },
  initialState,
)

// Selectors
export const getDailies = (state) => state.fractals.dailies
