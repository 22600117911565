import { globals } from '@discretize/globals';
import { Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import theme from '../../src/utils/themeOverride';
import React from 'react';

export default function TopLayout({ children }) {
  return (
    <>
      <Global styles={globals} />

      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
