/* eslint-disable no-console */
import axios from 'axios'
import { cancel, delay, fork, put, take } from 'redux-saga/effects'

import { update, UPDATES_STOP } from '.'

function* runUpdates() {
  try {
    while (true) {
      const { data } = yield axios.get(
        'https://api.guildwars2.com/v2/achievements/daily',
      )
      const { fractals } = data
      yield put(update(fractals.map(({ id }) => id)))
      // execute every 10 minutes
      yield delay(10 * 60 * 1000)
    }
  } catch (e) {
    console.error(e)
  }
}

export default function* () {
  const updatesTask = yield fork(runUpdates)

  yield take(UPDATES_STOP)
  yield cancel(updatesTask)
}
