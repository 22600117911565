import { APILanguageProvider } from '@discretize/gw2-ui-new';
import '@discretize/gw2-ui-new/dist/default_style.css';
import '@discretize/gw2-ui-new/dist/index.css';
import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import { Provider } from 'react-redux';
import createStore from './src/state/createStore';
import './src/utils/styleOverride.css';

const { store } = createStore();

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return (
    <Provider store={store}>
      <APILanguageProvider value="en">{element}</APILanguageProvider>
    </Provider>
  );
};
