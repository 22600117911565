import { combineReducers } from 'redux'
import { createAction, handleActions } from 'redux-actions'
import discord from './discord'
import editor from './editor'
import fractals from './fractals'

// State
const initialState = {
  global: {
    language: 'en',
    beginnerMode: [],
  },
}

// ActionTypes
export const ADD_BEGINNER_MODE = 'GENERAL/ADD_BEGINNER_MODE'
export const REMOVE_BEGINNER_MODE = 'GENERAL/REMOVE_BEGINNER_MODE'
export const SET_LANGUAGE = 'GENERAL/SET_LANGUAGE'

// Actions
export const addBeginner = createAction(ADD_BEGINNER_MODE)
export const removeBeginner = createAction(REMOVE_BEGINNER_MODE)
export const setLanguage = createAction(SET_LANGUAGE)

// Reducers
const general = handleActions(
  {
    [ADD_BEGINNER_MODE]: (state, { payload }) => ({
      ...state,
      global: {
        ...state.global,
        beginnerMode: state.global.beginnerMode.filter(
          (elem) => elem !== payload,
        ),
      },
    }),
    [REMOVE_BEGINNER_MODE]: (state, { payload }) => ({
      ...state,
      global: {
        ...state.global,
        beginnerMode: [...state.global.beginnerMode, payload],
      },
    }),
    [SET_LANGUAGE]: (state, { payload }) => ({
      ...state,
      global: {
        ...state.global,
        language: payload,
      },
    }),
  },
  initialState,
)

// Selectors
export const isBeginner = (title) => (state) => {
  return !state.general.global.beginnerMode.includes(title)
}
export const getLanguage = (state) => state.general.global.language

export default combineReducers({
  discord,
  fractals,
  editor,
  general,
})
